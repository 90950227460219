import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { useDispatch } from "react-redux";

import users from "../constants/api/users";

import { setAuthorizationHeader } from "configs/axios";

import { populateProfile } from "store/actions/users";

import useForm from "helpers/hooks/useForm";
import ValidationMessage from "components/Alerts/ValidationMessage";
import DefaultMessage from "components/Alerts/DefaultMessage";

function LoginForm({ history }) {
  const dispatch = useDispatch();

  const [{ username, password }, setState] = useForm({
    username: "",
    password: "",
  });

  const [loading, SetLoading] = useState(false);
  const [messageError, SetMessageError] = useState()
  const [usernameValidation, SetUsernameValidation] = useState()
  const [passwordValidation, SetPasswordValidation] = useState()

  function submit(e) {
    e.preventDefault();

    SetLoading(true);

    SetUsernameValidation('');
        SetPasswordValidation('');
        SetMessageError('');

    users
      .login({ username, password })
      .then((res) => {

        if(res.status === 'errorValidation'){
          console.log(res.message);

          const usernameError = res.message.filter((el) => { return el.field === 'username' })
          const passwordError = res.message.filter((el) => { return el.field === 'password' })

          SetUsernameValidation(usernameError[0].message);
          SetPasswordValidation(passwordError[0].message);
          return false;
        }

        if(res.status === 'error'){
          SetLoading(false);
          return SetMessageError(res.message);
        }

        // Set Authorization on Login
        setAuthorizationHeader(res.data.token);
        users.details().then((detail) => {
          dispatch(populateProfile(detail.data));
          const production =
            process.env.REACT_APP_FRONTPAGE_URL ===
            "http://admin.simulasiai.com"
              ? "Domain = admin.simulasiai.com"
              : "";
          localStorage.setItem(
            "SIM:token",
            JSON.stringify({
              ...res.data,
            })
          );

          const redirect = localStorage.getItem("SIM:redirect");
          const userCookie = {
            name: detail.data.name,
            thumbnail: detail.data.avatar,
          };

          const expires = new Date(
            new Date().getTime() + 7 * 24 * 60 * 60 * 1000
          );

          document.cookie = `SIM:user=${JSON.stringify(
            userCookie
          )}; expires=${expires.toUTCString()}; path:/; ${production}`;

          history.push(redirect || "/");
        });
        SetLoading(false);
      })
      .catch((error) => {

        if (error.response) {
          // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            // console.log(error.response.data);
            // console.log(error.response.status);
            // console.log(error.response.headers);
            // console.log('Error :', error?.response);
            SetMessageError(' Oops, please try again later !. ');
            SetLoading(false);
        } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the 
            // browser and an instance of
            // http.ClientRequest in node.js
            // console.log(error.request);
            // console.log('Error :', error?.request);
            SetMessageError(' Oops, please try again later !. ');
            SetLoading(false);
        } else {
            // Something happened in setting up the request that triggered an Error
            // console.log('Error :', error);
            SetMessageError(' Oops, please try again later !. ');
            SetLoading(false);
        }      
      });
    // SetLoading(false);
  }

  return (
    <div className="flex items-center justify-center pb-24">
      <div className="w-full sm:w-3/12 px-6 lg:px-0">
        <img className="mb-6" src="/assets/images/logo_simulasi.png" alt="logo simulasi"/>
        <form onSubmit={submit}>
          <div className="flex flex-col">
            <label htmlFor="username" className="mb-2 text-lg">
              Username
            </label>
            <input
              name="username"
              type="text"
              onChange={setState}
              className="w-full px-6 py-3 bg-white border border-gray-600 rounded-t-md focus:outline-none focus:border-teal-500"
              value={username}
              placeholder="Your username"
              // required
            />
          </div>

          {/* VALIDATION */}
          { usernameValidation &&  ( <ValidationMessage message={usernameValidation} /> )}

          <div className="flex flex-col mb-4">
            <label htmlFor="password" className="mb-2 text-lg">
              Password
            </label>
            <input
              name="password"
              type="password"
              onChange={setState}
              className="w-full px-6 py-3 bg-white border border-gray-600 rounded-t-md focus:outline-none focus:border-teal-500"
              value={password}
              placeholder="Your password addres"
              // required
            />
            
          </div>

          {/* VALIDATION */}
          { passwordValidation &&  ( <ValidationMessage message={passwordValidation} /> )}


          { loading ? (
            <button type="button" className="inline-flex w-full justify-center items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md text-white bg-blue-500 hover:bg-blue-400 transition ease-in-out duration-150 cursor-not-allowed" disabled="">
              <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
              Masuk...
          </button>
          ) : (
            <button
            type="submit"
            className="w-full px-6 py-3 mt-4 text-white transition-all duration-200 bg-blue-600 rounded-md shadow-inner hover:bg-blue-400 focus:outline-none"
          >
            Masuk
          </button>
          )}
          

          { messageError && ( <DefaultMessage message={messageError} />)}
        </form>
      </div>

      <div className="hidden w-1/12 sm:block"></div>

      <div className="justify-end hidden w-5/12 pt-24 pr-16 sm:block">
        <div className="relative" style={{ width: 369, height: 440 }}>
          <div
            className="absolute left-0 -mt-8 -ml-16 border-2 border-blue-700"
            style={{ width: 324, height: 374 }}
          ></div>
          <div className="absolute w-full h-full -mb-8 -ml-8">
            <img
              src="/assets/images/model.png"
              alt="Shafira Maharani"
            />
          </div>
          <div
            className="absolute bottom-0 right-0 z-10 px-4 py-3 -mr-12 bg-white"
            style={{ width: 290 }}
          >
            <p className="mb-2 text-gray-900">
              Simulasi UTBK !
            </p>
            <span className="text-gray-600">Shafira Maharani, Alumni AI</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(LoginForm);
