import React, { useEffect } from "react";

import _ from 'lodash';

import kelas from "constants/api/kelas";
import Sidebar from "parts/Sidebar";
import Loading from "parts/Loading";

import { useQuery } from 'react-query';
import { setAuthorizationHeader } from "configs/axios";
import Headbar from "parts/Headbar";
import StatusUjian from "parts/StatusUjian";
import KofirmasiUjian from "parts/KofirmasiUjian";
import EmptyTest from "parts/EmptyTest";


function EmptyState(props) {
  return (
    <section className="relative flex items-center h-screen bg-white">
      <div className="w-full py-12 mx-auto text-center sm:w-5/12">
        <img
          src={`${process.env.PUBLIC_URL}/assets/images/illustration-myclass-empty.jpg`}
          alt="-"
        />
        <h1 className="mt-12 text-3xl text-gray-900">Upps!</h1>
        <p className="mx-auto mt-4 mb-8 text-lg text-center text-gray-600">
          Oops, an error has been accured, please check again later !. 
        </p>
        <button  className="bg-orange-500 hover:bg-orange-400 transition-all duration-200 focus:outline-none shadow-inner rounded-md text-white px-6 py-3"
          type="submit"
          onClick="window.location.reload()">Coba Lagi</button>
        <p className="mx-auto mt-4 mb-8 text-sm text-center text-gray-600">{props.children ?? ''}</p>
      </div>
    </section>
  );
}

async function fetchTps() {
  if (localStorage.getItem("SIM:token")) {
    setAuthorizationHeader(await JSON.parse(localStorage.getItem("SIM:token")).token);
  }
  const data = await kelas.tpsKelas();
  return data;
}
async function fetchTka() {
  if (localStorage.getItem("SIM:token")) {
    setAuthorizationHeader(await JSON.parse(localStorage.getItem("SIM:token")).token);
  }
  const data = await kelas.tpaKelas();
  return data;
}

export default function MyUjian() {
  
  const { data: tpsData, isLoading: tpsIsLoading, isError: tpsIsError, error: tpsError } = useQuery('kelasTps', fetchTps);
  const { data: tkaData, isLoading: tkaIsLoading, isError: tkaIsError, error: tkaError } = useQuery('kelasTpa', fetchTka);

  useEffect(() => {
  }, [tpsData, tkaData]);

  return (
      <div className="flex bg-gray-250 min-h-screen">
        <Sidebar></Sidebar>
        <main className="flex-1">
          <div className="w-full h-16 bg-blue-500">
            <Headbar />
          </div>
          <div className="px-4 mt-10 sm:px-16">
              { tpsIsLoading ? (
                    <Loading></Loading>
                  ) : tpsError && tpsIsError ? (
                    <>
                    <EmptyState>{tpsError.message}</EmptyState>
                    </>
                  ) : (tpsData && !_.isEmpty(tpsData)) ? (

                    <div className="grid gap-4 lg:grid-cols-3 md:grid-cols-1">
                      <div className="col-span-2 mt-10 overflow-hidden bg-white shadow sm:rounded-lg ">
                        <KofirmasiUjian data={tpsData} title="TPS" />
                      </div>
                      <div className="mt-10">
                        <StatusUjian
                          statusUjian={tpsData?.status_ujian ?? 0}
                          mapelId={tpsData?.mapel_id_berlangsung}
                          statusFor="TPS"
                        />
                      </div>
                    </div>
                  ) : (
                    <EmptyTest title="Umum" />
                  )
              }

              { tkaIsLoading ? (
                    <Loading></Loading>
                  ) : tkaError && tkaIsError ? (
                    <>
                    <EmptyState>{tkaError.message}</EmptyState>
                    </>
                  ) : (tkaData && !_.isEmpty(tkaData)) ? (

                    <div className="grid gap-4 lg:grid-cols-3 md:grid-cols-1">
                      <div className="col-span-2 mt-10 overflow-hidden bg-white shadow sm:rounded-lg ">
                        <KofirmasiUjian data={tkaData} title="TKA"/>
                      </div>
                      <div className="mt-10">
                      <StatusUjian
                          statusUjian={tkaData?.status_ujian ?? 0}
                          mapelId={tkaData?.mapel_id_berlangsung}
                          tpsStatusUjian={tpsData?.status_ujian ?? 0}
                          statusFor="TKA"
                        />
                    </div>
                    </div>
                  ) : (
                    <></>
                  )
              }
              </div>
        </main>
      </div>
  );
}
