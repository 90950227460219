import React, { useEffect } from "react";
// import PropTypes from 'prop-types'
import { useState } from "react";
import { useQuery } from "react-query";
import _ from 'lodash';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { setAuthorizationHeader } from "configs/axios";
import ujian from "constants/api/ujian";
import Tipejawaban from "parts/ujian/tipejawaban";

import LoadingSoal from "./loadings/LoadingSoal";
import EmptySoal from "parts/EmptySoal";
import EmptyTest from "parts/EmptyTest";
import TimeLeft from "./TimeLeft";
import { useNomor } from "context/nomor";
import { useJawaban } from "context/jawaban";
import { useUpdateStatus } from "context/updatestatus";


async function fetchSoal(idMapel, nosoal = 1) {
  if (localStorage.getItem("SIM:token")) {
    setAuthorizationHeader(JSON.parse(localStorage.getItem("SIM:token")).token);
  }
  const dataSoal = await ujian.getSoal(idMapel, nosoal);
  return dataSoal;
}

export default function Soal(props) {
  const {idMapel, maxNumSoal, lama_ujian} = props;

  const nomorContext = useNomor();
  const { nomor, handleSetNomor } = nomorContext;

  const jawabanContext = useJawaban();
  const { jawaban, handleSetJawaban } = jawabanContext;

  const updateStatusContext = useUpdateStatus();
  const { handleSetUpdateStatus } = updateStatusContext;


  // const [jawab, setJawab] = useState();
  const [ragu, setRagu] = useState(false);


  const submitRagu = async (id_mapel, id_soal, jawaban, status_ragu) => {

    const idToastRagu = toast.loading("Kirim Jawaban Ragu...", { type: "default",
        isLoading: true,
        position: "bottom-center",
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        // progress: undefined,
        theme: "light",
      })


    if (localStorage.getItem("SIM:token")) {
      setAuthorizationHeader(await JSON.parse(localStorage.getItem("SIM:token")).token);
    }
  
    try {
      const postRagu = await ujian.jawabRagu({id_mapel, id_soal, jawaban, status_ragu});
      
      if(!postRagu || postRagu.status === 'fail'){
        toast.update(idToastRagu, { type: "error", render: "Ups!, Gagal mengirim Jawban Ragu, Silahkan di coba lagi!",
              isLoading: false,
              position: "bottom-center",
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
          });
      }

      if(postRagu.status === 'success'){
        setRagu(status_ragu);
        handleSetUpdateStatus();
        toast.update(idToastRagu, { type: "success", render: postRagu.message ?? 'Sukses',
            isLoading: false,
            position: "bottom-center",
            autoClose: 400,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
      }
    } catch (error) {
      // console.log(error);
      toast.update(idToastRagu, { type: "error", render: "Ups!, Gagal mengirim Jawban Ragu, Silahkan di coba lagi!",
            isLoading: false,
            autoClose: 3000,
            position: "bottom-center",
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    }
  }
  

  const { data: dataSoal, isLoading: isLoadingSoal, isError: isErrorSoal, error: errorSoal } = useQuery(['soal', idMapel, nomor], async () => fetchSoal(idMapel, nomor));

  useEffect(() => {
    handleSetJawaban('');
    setRagu('');

    handleSetJawaban(dataSoal?.jawab);
    setRagu(dataSoal?.ragu);
    // console.log('Render Componen Soal');
  },[dataSoal, nomor]);

  return (
    <>
      { isLoadingSoal ? (
          <LoadingSoal></LoadingSoal>
        ) : isErrorSoal ? (
          <>
            <EmptySoal>{errorSoal.message}</EmptySoal>
          </>
        ) : (dataSoal && !_.isEmpty(dataSoal)) ? (
          <>
            <div className="flex flex-row justify-between mb-3 px-4 py-3 rounded-t-lg sm:px-6 bg-gradient-to-br from-blue-400 to-blue-500">
              <div className="">
                <h3 className="text-lg font-medium leading-6 text-white">{props.title ?? ''}</h3>
                <h5 className="sm:text-md lg:text-md leading-6 text-white">Materi:  {dataSoal?.soal?.materi ?? ''}</h5>
              </div>
              <TimeLeft ujian_mulai={dataSoal.ujian_mulai} lama_ujian={lama_ujian} />
            </div>
            <div className="border-b-2 border-gray-200">
              <dl>
                <div className="px-4 py-5 bg-gray-50 sm:px-6">
                  <div className="text-sm font-medium text-gray-500">Soal No. {dataSoal?.soal?.nomor}</div>

                  { dataSoal?.soal?.file && (
                    <img className="sm:w-full md:w-1/2 pt-3" src={`${process.env.REACT_APP_FILE_HOST}/soal/${dataSoal?.soal?.file}`} alt="gambar1 soal" />
                  )}

                  <div className="mt-1 text-sm text-gray-900" dangerouslySetInnerHTML={{ __html: dataSoal?.soal?.soal }}></div>

                  { dataSoal?.soal?.file1 && (
                    <img className="sm:w-full md:w-1/2 pt-3" src={`${process.env.REACT_APP_FILE_HOST}/soal/${dataSoal?.soal?.file1}`} alt="gambar1 soal" />
                  )}

                </div>

                {/* PILIH JAWABAN */}
                <Tipejawaban idMapel={idMapel} piltype={dataSoal?.soal?.piltype} dataSoal={dataSoal}/>

                {/* BEFORE NEXT BUTTON */}
                <div className="px-4 py-5 bg-gray-50 sm:px-6">
                  <div className="flex flex-row justify-between">
                    { (dataSoal?.soal?.nomor > 1) ? (
                        <button type="button" className="py-1 px-3 mt-3 mb-5 text-center text-white bg-red-500 rounded-t-full rounded-bl-full ring-4 ring-yellow-200 cursor-pointer hover:bg-blue-400 active:bg-blue-700`"
                        onClick={() => handleSetNomor(dataSoal?.soal?.nomor-1)}>Soal Sebelumnya</button>
                    ) : (<div>&nbsp;</div>)}

                    { jawaban && (
                      <div className="px-3 py-1 mt-3 mb-5 text-center text-black bg-yellow-500 rounded-full cursor-pointer hover:bg-yellow-400 active:bg-yellow-700">
                        <input name="ragu" id="ragu" type="checkbox" checked={ragu}
                        onChange={(e) => {
                          submitRagu(idMapel, dataSoal?.soal?.id, jawaban, e.target.checked);
                        }}
                        /><label htmlFor="ragu"> Ragu - Ragu</label> 
                      </div>
                    )}

                    { (dataSoal?.soal?.nomor < maxNumSoal) ? (
                      <button type="button" className="px-3 py-1 mt-3 mb-5 text-center text-white bg-blue-500 rounded-t-full rounded-br-full ring-4 ring-yellow-200 cursor-pointer hover:bg-blue-400 active:bg-blue-700"
                      onClick={() => handleSetNomor(dataSoal?.soal?.nomor+1)}>
                         {/* <svg class="animate-spin h-5 w-5 mr-3" viewBox="0 0 24 24"></svg> */}
                        Soal Selanjutnya
                      </button>
                    ) : (<div>&nbsp;</div>)
                    }
                  </div>
                </div>
              </dl>
            </div>
          </>
        ) : (
          <EmptySoal />
        )
      }
      <ToastContainer />
    </>
  )
}

// Soal.propTypes = {
//   title: PropTypes.string.isRequired,
//   data: PropTypes.object.isRequired,
// }

// submitJawaban.propTypes = {
//   id_mapel: PropTypes.number.isRequired,
//   id_soal: PropTypes.number.isRequired,
//   jawaban: PropTypes.string.isRequired
// }

// submitRagu.propTypes = {
//   id_mapel: PropTypes.number.isRequired,
//   id_soal: PropTypes.number.isRequired,
//   jawaban: PropTypes.string.isRequired,
//   status_ragu: PropTypes.bool.isRequired
// }

