import { useEffect, useState, createContext, useContext } from "react";

export const NomorContext = createContext()

export const useNomor = () => {
    return useContext(NomorContext)
}


export const NomorProvider = ({ children }) => {
    const [nomor, setNomor] = useState(1)

    const handleSetNomor = (no) => setNomor(no);

    useEffect(() => {
        // console.log(`Nomor Provider : `, nomor)
    }, [nomor])

    return (
        <NomorContext.Provider value={{ nomor, handleSetNomor }}>
        {children}
        </NomorContext.Provider>
    )
}

export default NomorProvider;