import React from "react";

export default function LoadingNomor() {
  return (
    <section className="flex flex-col relative pb-5 z-50 bg-white rounded-b-lg">
      <div className="flex flex-row justify-end mb-3 px-4 py-3 rounded-t-lg sm:px-6 bg-gradient-to-br from-gray-400 to-gray-500">
        <div className="flex animate-pulse flex-row space-x-5 justify-end">
            <div className="w-12 bg-gray-300 h-3 rounded-md "></div>
        </div>
      </div>
      <div className="flex animate-pulse flex-row p-5 space-x-5">
        <div className="flex flex-col space-y-3 w-full">
            <div className=" w-full bg-gray-300 h-6 rounded-md "></div>
            <div className="flex flex-row space-x-3 justify-center">
              <div className="w-6 bg-gray-300 h-6 rounded-md "></div>
              <div className="w-6 bg-gray-300 h-6 rounded-md "></div>
              <div className="w-6 bg-gray-300 h-6 rounded-md "></div>
              <div className="w-6 bg-gray-300 h-6 rounded-md "></div>
              <div className="w-6 bg-gray-300 h-6 rounded-md "></div>
            </div>
            <div className="flex flex-row space-x-3 justify-center">
              <div className="w-6 bg-gray-300 h-6 rounded-md "></div>
              <div className="w-6 bg-gray-300 h-6 rounded-md "></div>
              <div className="w-6 bg-gray-300 h-6 rounded-md "></div>
              <div className="w-6 bg-gray-300 h-6 rounded-md "></div>
              <div className="w-6 bg-gray-300 h-6 rounded-md "></div>
            </div>

            <div className=" w-full bg-gray-300 h-6 rounded-md "></div>
            <div className="flex flex-row space-x-3 justify-center">
              <div className="w-6 bg-gray-300 h-6 rounded-md "></div>
              <div className="w-6 bg-gray-300 h-6 rounded-md "></div>
              <div className="w-6 bg-gray-300 h-6 rounded-md "></div>
              <div className="w-6 bg-gray-300 h-6 rounded-md "></div>
              <div className="w-6 bg-gray-300 h-6 rounded-md "></div>
            </div>
            <div className="flex flex-row space-x-3 justify-center">
              <div className="w-6 bg-gray-300 h-6 rounded-md "></div>
              <div className="w-6 bg-gray-300 h-6 rounded-md "></div>
              <div className="w-6 bg-gray-300 h-6 rounded-md "></div>
              <div className="w-6 bg-gray-300 h-6 rounded-md "></div>
              <div className="w-6 bg-gray-300 h-6 rounded-md "></div>
            </div>
        </div>
      </div>
    </section>
  );
}
