import React, { useEffect } from "react";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";
import { useDispatch } from "react-redux";

import "assets/css/style.css";

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import MemberRoute from "components/Routes/MemberRoute";
import GuestRoute from "components/Routes/GuestRoute";

import NotFound from "pages/404";
import Unauthenticated from "pages/401";
import users from "constants/api/users";

import Login from "pages/Login";
import Register from "pages/Register";
import RegisterSuccess from "pages/RegisterSuccess";

import MyUjian from "pages/MyUjian";


import { populateProfile } from "store/actions/users";
import Ujian from "pages/Ujian";
import Rules from "pages/Rules";
import Hasil from "pages/Hasil";
import Ebook from "pages/Ebook";
import { setAuthorizationHeader } from "configs/axios";


function App() {
  const dispatch = useDispatch();
  const history = createBrowserHistory({ basename: process.env.PUBLIC_URL });

  useEffect(() => {
      if (localStorage.getItem("SIM:token")) {
        setAuthorizationHeader(JSON.parse(localStorage.getItem("SIM:token")).token);
      }
      users.details().then((details) => {
        dispatch(populateProfile(details.data));
      });
    // }
  }, [dispatch]);
  return (
    <>
    
      <Router history={history}>
        <Switch>
          <GuestRoute path="/login" component={Login}></GuestRoute>
          <GuestRoute path="/register" component={Register}></GuestRoute>
          <GuestRoute path="/register-success" component={RegisterSuccess}></GuestRoute>
          <GuestRoute path="/private" component={Unauthenticated}></GuestRoute>

          <MemberRoute exact path="/" component={MyUjian}></MemberRoute>
          <MemberRoute exact path="/ujian/:idmapel" component={Ujian}></MemberRoute>
          <MemberRoute exact path="/rules" component={Rules}></MemberRoute>
          <MemberRoute exact path="/hasil" component={Hasil}></MemberRoute>
          <MemberRoute exact path="/ebooks" component={Ebook}></MemberRoute>

          <Route path="*" component={NotFound}></Route>
        </Switch>
      </Router>
      <ToastContainer />
    </>
  );
}

export default App;
