import React from "react";

export default function Footer() {
  return (
    <footer className="container mx-auto">
      <div className="pt-2 text-center border-t border-gray-800">
        <p className="text-indigo-500">
          2022 Copyright Amsterdam Institute. All Rights Reserved
        </p>
      </div>
    </footer>
  );
}
