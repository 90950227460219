import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { setAuthorizationHeader } from "configs/axios";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import ujian from 'constants/api/ujian';

import { useNomor } from "context/nomor";
import { useJawaban } from "context/jawaban";

Ceklis.prototypes = {
    idMapel: PropTypes.string.isRequired,
    idSoal: PropTypes.string.isRequired,
    dataSoal: PropTypes.object.isRequired,
}

function Ceklis(props) {
    const {idMapel, idSoal, dataSoal} = props;

    const nomorContext = useNomor();
    const { nomor } = nomorContext;

    const jawabanContext = useJawaban();
    const { handleSetJawaban } = jawabanContext;


    let ceklisJawaban = ['A','B','C','D','E']

    const [checkedState, setCheckedState] = useState(
      new Array(ceklisJawaban.length).fill(false)
    );

    async function handleCheckboxChange(itemKey) {
        const idToast = toast.loading("Menjawab...", { type: "default",
          isLoading: true,
          position: "bottom-center",
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          // progress: undefined,
          theme: "light",
      })


        const updatedCheckedState = checkedState.map((item, index) =>
          index === itemKey ? !item : item
        );

        setCheckedState(updatedCheckedState);

        const hasilJawaban = updatedCheckedState.reduce(
          (concat, currentState, index) => {
            if (currentState === true) {
              if(index !== 0){
                return concat + ';' + ceklisJawaban[index];
              } else {
                return ceklisJawaban[index];
              }
            }
            return concat;
          },
          ''
        );

        if (localStorage.getItem("SIM:token")) {
          setAuthorizationHeader(JSON.parse(localStorage.getItem("SIM:token")).token);
        }
        
        try {
          const postJawab = await ujian.jawabSoal({id_mapel: idMapel, id_soal: idSoal, jawaban: hasilJawaban});
          
          if(!postJawab || postJawab.status === 'fail'){
            toast.update(idToast, { type: "error", render: "Ups!, Gagal Menjawab Soal, Silahkan di coba lagi!",
                isLoading: false,
                position: "bottom-center",
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
          }

          if(postJawab.status === 'success'){
              handleSetJawaban(hasilJawaban);

              toast.update(idToast, { type: "success",  render: "Jawaban Terkirim",
                  isLoading: false,
                  position: "bottom-center",
                  autoClose: 300,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
              });
          }

        } catch (error) {
            // console.log(error);
            toast.update(idToast, { type: "error", render: "Ups!, Gagal Menjawab Soal, Silahkan di coba lagi!",
              isLoading: false,
              autoClose: 3000,
              position: "bottom-center",
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
          });

          const updatedCheckedState = checkedState.map((item, index) =>
            index === itemKey ? !item : item
          );
          setCheckedState(updatedCheckedState);
        }
    }
    
    
    useEffect(() => {
      handleSetJawaban('');

      // Clear checked
      setCheckedState([false,false,false,false,false])

      if(dataSoal?.jawab){
        const dapatJawaban = dataSoal?.jawab ?? '';
        // Deteksi tipe soal benar salah agar tidak di proses
        if(!dapatJawaban.includes('|')){
          let arrJawaban = dataSoal?.jawab.split(';');
          let newData = checkedState;
  
          arrJawaban.includes('A') ? newData[0] = true : newData[0] = false; 
          arrJawaban.includes('B') ? newData[1] = true : newData[1] = false; 
          arrJawaban.includes('C') ? newData[2] = true : newData[2] = false; 
          arrJawaban.includes('D') ? newData[3] = true : newData[3] = false; 
          arrJawaban.includes('E') ? newData[4] = true : newData[4] = false; 
  
          setCheckedState(newData);
          handleSetJawaban(dataSoal?.jawab);
        }
      }
    },[dataSoal, nomor]);
      

  return (
    <div className="px-4 py-5 bg-gray-50 sm:px-6">
        <div className="text-sm font-medium text-gray-500">Pilih Jawaban</div>
        <div className="p-5">

            <div>
                <div className="flex items-center mb-4">
                    <input name="chkJawab" id="chkJawab" type="checkbox" value="A"
                    checked={checkedState[0]}
                    onChange={() => handleCheckboxChange(0)}
                    className="chkJawab w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 cursor-pointer focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                    <label htmlFor="chkJawab" className="ml-2 text-sm font-medium text-gray-900" dangerouslySetInnerHTML={{ __html: dataSoal?.soal?.pilA }}></label>
                </div>
                <div className="pl-5">
                { dataSoal?.soal?.pilA_file && (
                    <img className="max-w-screen-md pb-5" src={`${process.env.REACT_APP_FILE_HOST}/soal/${dataSoal?.soal?.pilA_file}`} alt="gambar1 soal" />
                )}
                </div>
            </div>

            <div>
                <div className="flex items-center mb-4">
                    <input name="chkJawab" id="chkJawab" type="checkbox" value="B"
                    checked={checkedState[1]}
                    onChange={() => handleCheckboxChange(1)}
                    className="chkJawab w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 cursor-pointer focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                    <label htmlFor="chkJawab" className="ml-2 text-sm font-medium text-gray-900" dangerouslySetInnerHTML={{ __html: dataSoal?.soal?.pilB }}></label>
                </div>
                <div className="pl-5">
                { dataSoal?.soal?.pilB_file && (
                    <img className="max-w-screen-md pb-5" src={`${process.env.REACT_APP_FILE_HOST}/soal/${dataSoal?.soal?.pilB_file}`} alt="gambar1 soal" />
                )}
                </div>
            </div>

            <div>
                <div className="flex items-center mb-4">
                    <input name="chkJawab" id="chkJawab" type="checkbox" value="C"
                    checked={checkedState[2]}
                    onChange={() => handleCheckboxChange(2)}
                    className="chkJawab w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 cursor-pointer focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                    <label htmlFor="chkJawab" className="ml-2 text-sm font-medium text-gray-900" dangerouslySetInnerHTML={{ __html: dataSoal?.soal?.pilC }}></label>
                </div>
                <div className="pl-5">
                { dataSoal?.soal?.pilC_file && (
                    <img className="max-w-screen-md pb-5" src={`${process.env.REACT_APP_FILE_HOST}/soal/${dataSoal?.soal?.pilC_file}`} alt="gambar1 soal" />
                )}
                </div>
            </div>

            <div>
                <div className="flex items-center mb-4">
                    <input name="chkJawab" id="chkJawab" type="checkbox" value="D"
                    checked={checkedState[3]}
                    onChange={() => handleCheckboxChange(3)}
                    className="chkJawab w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 cursor-pointer focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                    <label htmlFor="chkJawab" className="ml-2 text-sm font-medium text-gray-900" dangerouslySetInnerHTML={{ __html: dataSoal?.soal?.pilD }}></label>
                </div>
                <div className="pl-5">
                { dataSoal?.soal?.pilD_file && (
                    <img className="max-w-screen-md pb-5" src={`${process.env.REACT_APP_FILE_HOST}/soal/${dataSoal?.soal?.pilD_file}`} alt="gambar1 soal" />
                )}
                </div>
            </div>

            <div>
                <div className="flex items-center mb-4">
                    <input name="chkJawab" id="chkJawab" type="checkbox" value="E"
                    checked={checkedState[4]}
                    onChange={() => handleCheckboxChange(4)}
                    className="chkJawab w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 cursor-pointer focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                    <label htmlFor="chkJawab" className="ml-2 text-sm font-medium text-gray-900"dangerouslySetInnerHTML={{ __html: dataSoal?.soal?.pilE }}></label>
                </div>
                <div className="pl-5">
                { dataSoal?.soal?.pilE_file && (
                    <img className="max-w-screen-md pb-5" src={`${process.env.REACT_APP_FILE_HOST}/soal/${dataSoal?.soal?.pilE_file}`} alt="gambar1 soal" />
                )}
                </div>
            </div>
        </div>
        <ToastContainer />
    </div>
  )
}

export default Ceklis

// Expected data : A;B;C