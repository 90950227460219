import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom'
import ujian from 'constants/api/ujian';


TimeLeft.prototypes = {
    ujian_mulai: PropTypes.string.isRequired,
    lama_ujian: PropTypes.string.isRequired,
}


function TimeLeft(props) {
    const history = useHistory();
    const { ujian_mulai, lama_ujian } = props;
    const { idmapel } = useParams();

    const [times, setTimes] = useState(new Date(ujian_mulai.replace(/-/g, '/')))
    const [lock, setLock] = useState(true)

    const fetchTimeServer = async () => {
      const getTime = await ujian.getTime();
      setTimes(new Date(getTime.replace(/-/g, '/')))
    }
    
    const calculateTimeLeft = (ujian_berakhir) => {
      let timeLeft = {};
      
      if(ujian_berakhir !== undefined){
        var tzUjianMulai = new Date(ujian_berakhir);
        var tzDateNow = times;

        let difference = tzUjianMulai - tzDateNow;

        if (difference > 0) {
          // console.log('difference > 0 ' +difference);

          timeLeft = {
            days: Math.floor(difference / (1000 * 60 * 60 * 24)),
            hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
            minutes: Math.floor((difference / 1000 / 60) % 60),
            seconds: Math.floor((difference / 1000) % 60)
          };
        } else {
            ujian.updateSelesai(idmapel);
            history.push(`/rules`);
        }
      }
    
      return timeLeft;
    }

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
    
    
    useEffect(() => {
        const convertUjianMulai = new Date(ujian_mulai.replace(/-/g, '/'));
        var ujianBerakhir = new Date(convertUjianMulai.getTime() + Number(lama_ujian)*60000);
        
        const timer = setTimeout(() => {
            // Buat Lock agak hanya sekali fetch time yang selanjutnya time akan di kurangkan secara lokal
            if(lock){
              fetchTimeServer().then(() => {
                setLock(false);
              });
            } else {
              console.log('done time');
              setTimes(new Date(times).getTime()+1000)
            }
            
            // Kalkulasi Waktu
            setTimeLeft(calculateTimeLeft(ujianBerakhir));
        }, 1000);

        // console.log(`Render Componen TimeLeft: ${timeLeft}`);
        
        return () => clearTimeout(timer);
        
      },[timeLeft, props]);

      return (
        <div className='flex flex-col lg:flex-row rounded-lg  overflow-hidden'>
            <div className='px-3 py-2 bg-gray-300'>Sisa Waktu: </div>
            <div className="px-3 py-2 text-center bg-yellow-500 items-center"><b>{timeLeft?.hours ?? '00'}:{timeLeft?.minutes ?? '00'}:{timeLeft?.seconds ?? '00'}</b> </div>
        </div>
      );
}

export default TimeLeft