import React, { useEffect } from 'react';
import PropTypes from 'prop-types'

import Pilihan from 'parts/ujian/tipejawaban/Pilihan';
import Ceklis from 'parts/ujian/tipejawaban/Ceklis';
import Benarsalah from 'parts/ujian/tipejawaban/Benarsalah';


Tipejawaban.propTypes = {
    piltype: PropTypes.string.isRequired,
    dataSoal: PropTypes.object.isRequired,
}


export default function Tipejawaban(props) {
    const {idMapel, dataSoal, piltype} = props;

    useEffect(() => {
        
    },[dataSoal]);

  if(piltype === 'CEKLIS'){
    return (
        <>
          <Ceklis idMapel={idMapel} idSoal={dataSoal?.soal?.id} dataSoal={dataSoal} />
        </>
      );
  } else if (piltype === 'BENARSALAH'){
    return (
        <>
          <Benarsalah idMapel={idMapel} idSoal={dataSoal?.soal?.id} dataSoal={dataSoal} />
        </>
      );
  } else {
    return (
        <>
          <Pilihan idMapel={idMapel} idSoal={dataSoal?.soal?.id} dataSoal={dataSoal}  />
        </>
      );
  }
}
