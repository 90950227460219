import { useEffect, useState, createContext, useContext } from "react";

export const jawabanContext = createContext()

export const useJawaban = () => {
    return useContext(jawabanContext)
}

export const JawabanProvider = ({ children }) => {
    const [jawaban, setJawaban] = useState()

    const handleSetJawaban = (jawab) => setJawaban(jawab);

    useEffect(() => {
        // console.log(`Jawaban Provider : `, jawaban)
    }, [jawaban])

    return (
        <jawabanContext.Provider value={{ jawaban, handleSetJawaban }}>
            {children}
        </jawabanContext.Provider>
    )
}

export default JawabanProvider;