import { useEffect, useState, createContext, useContext } from "react";

export const UpdateStatusContext = createContext()

export const useUpdateStatus = () => {
    return useContext(UpdateStatusContext)
}


export const UpdateStatusProvider = ({ children }) => {
    const [updateStatus, setUpdateStatus] = useState(false)

    const handleSetUpdateStatus = () => setUpdateStatus((updateStatus) => !updateStatus);

    useEffect(() => {
        // console.log(`UpdateStatus Provider : `, updateStatus)
    }, [updateStatus])

    return (
        <UpdateStatusContext.Provider value={{ updateStatus, handleSetUpdateStatus }}>
        {children}
        </UpdateStatusContext.Provider>
    )
}

export default UpdateStatusProvider;