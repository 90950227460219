import { useEffect } from "react";
import _ from 'lodash';

import Sidebar from "parts/Sidebar";
import Loading from "parts/Loading";

import { setAuthorizationHeader } from "configs/axios";
import ebook from "constants/api/ebook";
import Headbar from "parts/Headbar";
import { useQuery } from 'react-query';

// import { useSelector } from "react-redux";


async function fetchHasil() {
  if (localStorage.getItem("SIM:token")) {
    setAuthorizationHeader(await JSON.parse(localStorage.getItem("SIM:token")).token);
  }
  const result = await ebook.getActiveEbook();
  return result;
}

export default function Hasil() {
  
  const { data: hasilData, isLoading: hasilIsLoading, isError: hasilIsError, error: hasilError } = useQuery('ebooks', fetchHasil);
  // const USERS = useSelector((state) => state.users);

  useEffect(() => {
  }, [hasilData]);

  return (
      <div className="flex bg-gray-250">
        <Sidebar></Sidebar>
        <main className="flex-1">
          <div className="w-full h-16 bg-blue-500">
            <Headbar />
          </div>
          <div className="px-4 mt-10 sm:px-16">
              { hasilIsLoading ? (
                    <Loading></Loading>
                  ) :hasilError &&hasilIsError ? (
                    <>
                    <b>There's an error: {hasilError.message}</b>
                    <div>---No Record Found---</div>
                    </>
                  ) : (hasilData && !_.isEmpty(hasilData)) ? (
                    <section className="relative flex flex-col h-screen bg-white p-5">
                      <div className="flex gap-4 mb-5">
                        <div>
                          <div className="text-2xl font-semibold">EBOOK</div>
                        </div>
                      </div>
                      <div id="TKA" className="mb-6 text-left">
                        <ul>
                            { hasilData?.map((item, index) => {
                              return (
                                <li key={index} className="p-3 bg-gray-200 mb-3 rounded-md lg:flex justify-between sm:flex-none">
                                  <div>
                                    <div className="mb-2 font-bold text-md">{item?.judul_buku}</div>
                                    <div className="text-sm">{item?.keterangan}</div>
                                  </div>
                                  <div className="my-5 ">
                                    <a href={`${process.env.REACT_APP_FILE_HOST}/ebook/${item?.file}`} rel="noreferrer" target="_blank" alt="fileEbook"
                                    className=" bg-green-400 hover:bg-green-300 p-3 rounded-lg">DOWNLOAD</a>
                                  </div>
                                </li>
                              );
                            })}
                        </ul>
                      </div>
                            

                    </section>
                  ) : (
                    <div className="h-screen">
                      <div className="px-6 py-5 mb-4 text-base text-green-700 bg-green-100 rounded-lg" role="alert">
                        <h4 className="mb-2 text-2xl font-medium leading-tight">Tidak Ada Ebook!</h4>
                        <p className="mb-4">
                          Hubungi Admin jika ebook anda belum tampil
                        </p>
                      </div>
                    </div>
                  )
              }
              </div>
        </main>
      </div>
  );
}
