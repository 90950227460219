import { toast } from "react-toastify";
import users from "constants/api/users";

import axios, { setAuthorizationHeader } from "./index";

export default function errorHandler(error) {
  if (error) {
    // console.log(error);

    let message;

    if (error.response){
      const originalRequest = error.config;
  
      if (error.response.status === 500){
          message = "Something went terribly wrong";

          if(error.response.message === 'The token has been blacklisted'){
            alert('Login Expired, silahkan Login Ulang');
            window.location.href = "/login";
            localStorage.removeItem("SIM:token");
          }
      }  else if (error.response.status === 401) {
          message = "Refresh Token Expired";
          alert('Login Expired, silahkan Login Ulang');
          window.location.href = "/login";
          localStorage.removeItem("SIM:token");
      } else if (error.response.status === 403 && !originalRequest._retry) {
          // Jika mendapatkan error 403 dari verifyToken backend maka refresh tokennya
          originalRequest._retry = true;
          const session = localStorage["SIM:token"] ? JSON.parse(localStorage["SIM:token"]) : null;
  
          return users
            .refresh({
              refresh_token: session.refresh_token,
              username: session.username,
              name: session.name,
            })
            .then((res) => {
  
              if(res.data.status === 'error'){
                toast.error(message);
                // window.location.href = "/login";
                // localStorage.removeItem("SIM:token");
              }
  
              if(res.data) {
                // setAuthorizationHeader(res.data.token);
                localStorage.setItem(
                  "SIM:token",
                  JSON.stringify({
                    ...session,
                    token: res.data.token,
                  })
                );
  
                originalRequest.headers.authorization = res.data.token;
  
                return axios(originalRequest);
              } else {
                console.log('res.data not found : '+error);
                // window.location.href = "/login";
                // localStorage.removeItem("SIM:token");
              }
            });
      } else {
        message = error.response.data.message;
      }
      
      if (typeof message === "string") {
        toast.error(message);
        console.log('Error '+message);
      }
  
      return Promise.reject(error);
    }
  }
}
