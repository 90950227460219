import React from "react";
import { TiWarningOutline } from "react-icons/ti";
import { Link } from "react-router-dom";

export default function StatusUjian(props) {
  const statusUjian = props.statusUjian;
  const mapelId = props.mapelId;
  const tpsStatusUjian = props.tpsStatusUjian;
  const statusFor = props.statusFor;

  let text = 'Lanjutkan';
  let color = 'gray';
  let warningStatus = '';

  switch (statusUjian) {
    case 0:
      text = 'Mulai';
      color = 'bg-blue-500';
      break;
    case 1:
      text = 'Lanjutkan';
      color = 'bg-green-500';
      warningStatus = true;
      break;
    case 2:
      text = 'Selesai';
      color = 'bg-gray-500';
      break;
    default:
      text = 'Selesai';
      color = 'bg-gray-500';
      break;
  }

  if(mapelId === undefined || mapelId === '' || mapelId === false){
    return (
    <>-</>
    )
  }

  if(statusFor === 'TKA' && tpsStatusUjian !== 2){
    return (
      <>
      {warningStatus && (
        <div className="p-5 font-bold text-white rounded-lg bg-gradient-to-br from-yellow-400 to-orange-400">
          <TiWarningOutline size={20} className="float-left"/> &nbsp; Selesaikan Ujian TPS terlebih dahulu
        </div>
        )}
      </>
    )
  }

  if(statusUjian === 2){
    return (
      <div className={`p-5 mt-3 mb-5 font-bold text-center text-white ${color} rounded-lg`}>
        {text}
      </div>
    )
  }
  

  return (
    <>
    {warningStatus && (
      <div className="p-5 font-bold text-white rounded-lg bg-gradient-to-br from-yellow-400 to-orange-400">
        <TiWarningOutline size={20} className="float-left"/> &nbsp; Ujian belum selesai. Silahkan LANJUTKAN dengan mengklik tombol dibawah ini. 
      </div>
      )}
      
      <Link
        to={`/ujian/${mapelId}`}
      >
        <div className={`p-5 mt-3 mb-5 font-bold text-center text-white ${color} rounded-lg cursor-pointer hover:opacity-75`}>
        {text}
        </div>
      </Link>
    </>
  )
  ;
}
