import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { setAuthorizationHeader } from "configs/axios";
import ujian from 'constants/api/ujian';
import { useJawaban } from "context/jawaban";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

Benarsalah.prototypes = {
    idMapel: PropTypes.string.isRequired,
    idSoal: PropTypes.string.isRequired,
    dataSoal: PropTypes.object.isRequired,
}

function Benarsalah(props) {
    const {idMapel, idSoal, dataSoal} = props;

    const jawabanContext = useJawaban();
    const { handleSetJawaban } = jawabanContext;

    async function handleTrueFalse(){

      const idToast = toast.loading("Menjawab...", { type: "default",
        isLoading: true,
        position: "bottom-center",
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        // progress: undefined,
        theme: "light",
      })

      var hasilJawaban = '';
      var n = idSoal; // nomor soal
      // var arr = 'ABCDE';
      var arr = ['A','B','C','D','E'];

      for(let i=1;i<=5;i++)  {
        // Cari semua name selector yang di pilih
        var v = document.querySelectorAll('input[name="pilihan_'+n+'_'+i+'"]:checked')[0]?.value;

        // menhasilkan model jawaban seperti ini : A|1;B|2;C|1
        if (v !== undefined && v != null){
          if(hasilJawaban === '' || hasilJawaban === null){
            hasilJawaban += arr[(i-1)]+'|'+v
          } else {
            hasilJawaban += ';'+arr[(i-1)]+'|'+v
          }
        }
      }

      if (localStorage.getItem("SIM:token")) {
        setAuthorizationHeader(await JSON.parse(localStorage.getItem("SIM:token")).token);
      }
        
      try {
        const postJawab = await ujian.jawabSoal({id_mapel: idMapel, id_soal: idSoal, jawaban: hasilJawaban});
        if(!postJawab || postJawab.status === 'fail'){
          toast.update(idToast, { type: "error", render: "Ups!, Gagal Menjawab Soal, Silahkan di coba lagi!",
              isLoading: false,
              position: "bottom-center",
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
          });
        }

        if(postJawab.status === 'success'){
          handleSetJawaban(hasilJawaban);

          toast.update(idToast, { type: "success", render: "Jawaban Terkirim",
              isLoading: false,
              position: "bottom-center",
              autoClose: 300,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
          });
      }

      } catch (error) {
        // console.log(error);
        toast.update(idToast, { type: "error", render: "Ups!, Gagal Menjawab Soal, Silahkan di coba lagi!",
          isLoading: false,
          autoClose: 3000,
          position: "bottom-center",
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
    
    
    useEffect(() => {
        handleSetJawaban('');

        // Clear checked
        for(let i=1;i<=5;i++)  {
          Array.from( document.querySelectorAll('.rdo_pilihan'), input => input.checked = false );
        }

        // initiate checked
        if(dataSoal?.jawab){

          // Deteksi tipe Soal Benar salah
          if(dataSoal?.jawab.includes('|1') || dataSoal?.jawab.includes('|2')){
              let arrJawaban = dataSoal?.jawab.split(';');
              var arr = ['A','B','C','D','E'];
  
              arrJawaban.forEach(value => {
                let varPil = value.split('|')[0]; // amabil huruf
                let varChecked = value.split('|')[1]; // ambil Nilai checked benar atau salah
                let pilih = arr.indexOf(varPil)+1;
    
                document.getElementById('pilihan_'+idSoal+'_'+pilih+'_'+varChecked).checked = true;
              });
    
              handleSetJawaban(dataSoal?.jawab);
            }
          }

      },[dataSoal, idSoal]);
      

  return (
    <div className="px-4 py-5 bg-gray-50 sm:px-6">
        <table className="table-auto w-full">
          <thead>
            <tr className='bg-gray-100'>
              <th className='border-2 border-gray-500 px-4 py-2 text-left w-10/12'>Pernyataan</th>
              <th className='border-2 border-gray-500 px-4 py-2 text-center'>Benar</th>
              <th className='border-2 border-gray-500 px-4 py-2 text-center'>Salah</th>
            </tr>
          </thead>
          <tbody>
              {/* Pilihan A */}
              <tr>
                <td className='border-2 border-gray-500 px-4 py-2 text-left'>
                  <label htmlFor="rdoJawab" className="ml-2 text-sm font-medium text-gray-900" dangerouslySetInnerHTML={{ __html: dataSoal?.soal?.pilA }}></label>
                </td>
                <td className='border-2 border-gray-500 px-4 py-2  text-center'>
                  <div className="checkbox icheck-primary">
                    <input id={`pilihan_${idSoal}_1_1`} name={`pilihan_${idSoal}_1`} className="rdo_pilihan" onChange={(e) => { handleTrueFalse('A', e.target.value) }} type="radio" value="1" />
                    <label htmlFor={`pilihan_${idSoal}_1_1`}></label>
                  </div>
                </td>
                <td className='border-2 border-gray-500 px-4 py-2  text-center'>
                  <div className="checkbox icheck-primary">
                    <input id={`pilihan_${idSoal}_1_0`} name={`pilihan_${idSoal}_1`} className="rdo_pilihan" onChange={(e) => { handleTrueFalse('A', e.target.value) }} type="radio" value="0" />
                    <label htmlFor={`pilihan_${idSoal}_1_0`}></label>
                  </div>
                </td>
              </tr>
              
              {/* Pilihan B */}
              <tr>
                <td className='border-2 border-gray-500 px-4 py-2 text-left'>
                  <label htmlFor="rdoJawab" className="ml-2 text-sm font-medium text-gray-900" dangerouslySetInnerHTML={{ __html: dataSoal?.soal?.pilB }}></label>
                  </td>
                <td className='border-2 border-gray-500 px-4 py-2  text-center'>
                  <div className="checkbox icheck-primary">
                    <input id={`pilihan_${idSoal}_2_1`} name={`pilihan_${idSoal}_2`} className="rdo_pilihan" onChange={(e) => { handleTrueFalse('B', e.target.value) }} type="radio" value="1" />
                    <label htmlFor={`pilihan_${idSoal}_2_1`}></label>
                  </div>
                </td>
                <td className='border-2 border-gray-500 px-4 py-2  text-center'>
                  <div className="checkbox icheck-primary">
                    <input id={`pilihan_${idSoal}_2_0`} name={`pilihan_${idSoal}_2`} className="rdo_pilihan" onChange={(e) => { handleTrueFalse('B', e.target.value) }} type="radio" value="0" />
                    <label htmlFor={`pilihan_${idSoal}_2_0`}></label>
                  </div>
                </td>
              </tr>

              {/* Pilihan C */}
              <tr>
                <td className='border-2 border-gray-500 px-4 py-2 text-left'>
                  <label htmlFor="rdoJawab" className="ml-2 text-sm font-medium text-gray-900" dangerouslySetInnerHTML={{ __html: dataSoal?.soal?.pilC }}></label>
                  </td>
                <td className='border-2 border-gray-500 px-4 py-2  text-center'>
                  <div className="checkbox icheck-primary">
                    <input id={`pilihan_${idSoal}_3_1`} name={`pilihan_${idSoal}_3`} className="rdo_pilihan" onChange={(e) => { handleTrueFalse('C', e.target.value) }} type="radio" value="1" />
                    <label htmlFor={`pilihan_${idSoal}_3_1`}></label>
                  </div>
                </td>
                <td className='border-2 border-gray-500 px-4 py-2  text-center'>
                  <div className="checkbox icheck-primary">
                    <input id={`pilihan_${idSoal}_3_0`} name={`pilihan_${idSoal}_3`} className="rdo_pilihan" onChange={(e) => { handleTrueFalse('C', e.target.value) }} type="radio" value="0" />
                    <label htmlFor={`pilihan_${idSoal}_3_0`}></label>
                  </div>
                </td>
              </tr>

              {/* Pilihan D */}
              <tr>
                <td className='border-2 border-gray-500 px-4 py-2 text-left'>
                  <label htmlFor="rdoJawab" className="ml-2 text-sm font-medium text-gray-900" dangerouslySetInnerHTML={{ __html: dataSoal?.soal?.pilD }}></label>
                  </td>
                <td className='border-2 border-gray-500 px-4 py-2  text-center'>
                  <div className="checkbox icheck-primary">
                    <input id={`pilihan_${idSoal}_4_1`} name={`pilihan_${idSoal}_4`} className="rdo_pilihan" onChange={(e) => { handleTrueFalse('D', e.target.value) }} type="radio" value="1" />
                    <label htmlFor={`pilihan_${idSoal}_4_1`}></label>
                  </div>
                </td>
                <td className='border-2 border-gray-500 px-4 py-2  text-center'>
                  <div className="checkbox icheck-primary">
                    <input id={`pilihan_${idSoal}_4_0`} name={`pilihan_${idSoal}_4`} className="rdo_pilihan" onChange={(e) => { handleTrueFalse('D', e.target.value) }} type="radio" value="0" />
                    <label htmlFor={`pilihan_${idSoal}_4_0`}></label>
                  </div>
                </td>
              </tr>
              
              {/* Pilihan E */}
              <tr>
                <td className='border-2 border-gray-500 px-4 py-2 text-left'>
                  <label htmlFor="rdoJawab" className="ml-2 text-sm font-medium text-gray-900" dangerouslySetInnerHTML={{ __html: dataSoal?.soal?.pilE }}></label>
                  </td>
                <td className='border-2 border-gray-500 px-4 py-2  text-center'>
                  <div className="checkbox icheck-primary">
                    <input id={`pilihan_${idSoal}_5_1`} name={`pilihan_${idSoal}_5`} className="rdo_pilihan" onChange={(e) => { handleTrueFalse('E', e.target.value) }} type="radio" value="1" />
                    <label htmlFor={`pilihan_${idSoal}_5_1`}></label>
                  </div>
                </td>
                <td className='border-2 border-gray-500 px-4 py-2  text-center'>
                  <div className="checkbox icheck-primary">
                    <input id={`pilihan_${idSoal}_5_0`} name={`pilihan_${idSoal}_5`} className="rdo_pilihan" onChange={(e) => { handleTrueFalse('E', e.target.value) }} type="radio" value="0" />
                    <label htmlFor={`pilihan_${idSoal}_5_0`}></label>
                  </div>
                </td>
              </tr>

          </tbody>
        </table>
        <ToastContainer />
    </div>
  )
}

export default Benarsalah


// Expected data : A|1;B|2;C|1