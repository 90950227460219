import { useEffect } from "react";
import { setAuthorizationHeader } from "configs/axios";
import _ from 'lodash';
import { useQuery } from 'react-query';
import { useSelector } from "react-redux";

import Sidebar from "parts/Sidebar";
import Loading from "parts/Loading";
import QRCode from "react-qr-code";

import hasil from "constants/api/hasil";
import Headbar from "parts/Headbar";

async function fetchHasil() {
  if (localStorage.getItem("SIM:token")) {
    setAuthorizationHeader(JSON.parse(localStorage.getItem("SIM:token")).token);
  }
  const result = await hasil.score();
  return result;
}

export default function Hasil() {
  
  const { data: hasilData, isLoading: hasilIsLoading, isError: hasilIsError, error: hasilError } = useQuery('hasilUjian', fetchHasil);
  const USERS = useSelector((state) => state.users);

  useEffect(() => {
  }, [hasilData]);

  return (
      <div className="flex bg-gray-250">
        <Sidebar></Sidebar>
        <main className="flex-1">
          <div className="w-full h-16 bg-blue-500">
            <Headbar />
          </div>
          <div className="px-4 mt-10 sm:px-16">
              { hasilIsLoading ? (
                    <Loading></Loading>
                  ) :hasilError &&hasilIsError ? (
                    <>
                    <b>There's an error: {hasilError.message}</b>
                    <div>---No Record Found---</div>
                    </>
                  ) : (hasilData && !_.isEmpty(hasilData)) ? (
                    <section className="flex flex-col items-center justify-center h-screen bg-white">
                      <div className="border-2 border-dashed text-center">
                        <div className="flex gap-4 items-center justify-center p-5">
                          <img className="mb-0 max-w-full h-14" src="/assets/images/logo_simulasi.png" alt="logo simulasi"/>
                          <div>
                            <div className="sm:text-sm lg:text-2xl font-bold">SIMULASI UTBK</div>
                            <div className="sm:text-sm lg:text-2xl font-bold">AMSTERDAM INSTITUTE</div>
                          </div>
                        </div>
                        <div className="mt-6 text-center">
                          <div className="text-xs lg:text-2xl">Pengumuman Hasil Ujian</div>
                          <div className="text-xs lg:text-2xl">Ujian Tulis Berbasis Komputer (UTBK)</div>
                        </div>
                        <div className="w-5/6 border-b-2 border-black text-center mx-auto"></div>
                        <div >
                        <table className="mt-6 table-auto mx-auto text-xs lg:text-lg text-left">
                            <tbody>
                              <tr>
                                <td className="p-1">Nama</td>
                                <td className="p-1">:</td>
                                <td className="p-1">{USERS?.nama}</td>
                              </tr>
                              <tr>
                                <td className="p-1">Tempat / Tanggal Lahir</td>
                                <td className="p-1">:</td>
                                <td className="p-1">{USERS?.lahir ?? ''}, {USERS?.tgl_lahir ?? ''}</td>
                              </tr>
                              <tr>
                                <td className="p-1">NIS</td>
                                <td className="p-1">:</td>
                                <td className="p-1">{USERS?.nis ?? ''}</td>
                              </tr>
                              <tr>
                                <td className="p-1">Asal Sekolah</td>
                                <td className="p-1">:</td>
                                <td className="p-1">{USERS?.asal_sekolah ?? ''}</td>
                              </tr>
                              <tr>
                                <td className="p-1">Nomor Peserta</td>
                                <td className="p-1">:</td>
                                <td className="p-1">{USERS?.no_peserta ?? ''}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div className="mt-6 text-center text-xs lg:text-lg px-5">
                          <p>Telah Mengikuti Simulasi UTBK yang diselenggarakan oleh Amsterdam Institute</p>
                        </div>

                        <div className="flex justify-center w-5/6 gap-3 mt-6 text-center mx-auto">
                          <div className="w-64 h-64 bg-gray-400 hidden lg:block">
                              <QRCode value="Telah Mengikuti Simulasi UTBK yang diselenggarakan oleh Amsterdam Institute" />
                          </div>
                          <div>
                            <div id="TPS" className="mb-6 text-left">
                              <h5 className="font-bold">Nilai TPS</h5>
                              <table className="w-full table-auto  text-xs lg:text-lg">
                                { hasilData?.tps?.map((item, index) => {
                                  return (
                                    <tr>
                                      <td>{item?.mapel}</td>
                                      <td className="pl-3"><b>{item?.score_irt ?? '-'}</b></td>
                                    </tr>
                                  );
                                })}
                              </table>
                            </div>
                            <div id="TKA" className="mb-6 text-left">
                              {hasilData?.tka && (<h5 className="font-bold">Nilai TKA</h5>)}
                              
                              <table className="w-full table-auto  text-xs lg:text-lg">
                                { hasilData?.tka?.map((item, index) => {
                                  return (
                                    <tr>
                                      <td>{item?.mapel}</td>
                                      <td className="pl-3"><b>{item?.score_irt ?? '-'}</b></td>
                                    </tr>
                                  );
                                })}
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  ) : (
                    <div className="h-screen">
                      <div className="px-6 py-5 mb-4 text-base text-green-700 bg-green-100 rounded-lg" role="alert">
                        <h4 className="mb-2 text-2xl font-medium leading-tight">Score Belum Tersedia!</h4>
                        <p className="mb-4">
                        Anda Belum menyelesaikan seluruh Ujian
                        </p>
                        <hr className="border-green-600 opacity-30" />
                        <p className="mt-4 mb-0">
                          Silahkan Selesaikan terlebih dahulu atau tunggu hasil pengumuman
                        </p>
                      </div>
                    </div>
                  )
              }
              </div>
        </main>
      </div>
  );
}
