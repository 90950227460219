import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { setAuthorizationHeader } from "configs/axios";
import ujian from 'constants/api/ujian';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// import { useNomor } from "context/nomor";

import { useJawaban } from "context/jawaban";
import { useUpdateStatus } from "context/updatestatus";

Pilihan.prototypes = {
    idMapel: PropTypes.string.isRequired,
    dataSoal: PropTypes.object.isRequired,
}

function Pilihan(props) {
    const {idMapel, dataSoal} = props;

    const updateStatusContext = useUpdateStatus();
    const { handleSetUpdateStatus } = updateStatusContext;

    const jawabanContext = useJawaban();
    const { jawaban, handleSetJawaban } = jawabanContext;

    const submitJawaban = async (id_mapel, id_soal, hasilJawaban) => {
        if (localStorage.getItem("SIM:token")) {
            setAuthorizationHeader(await JSON.parse(localStorage.getItem("SIM:token")).token);
        }
        
        const idToast = toast.loading("Menjawab...", {
            type: "default",
            isLoading: true,
            position: "bottom-center",
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            // progress: undefined,
            theme: "light",
        })

        try {
            const postJawab = await ujian.jawabSoal({id_mapel, id_soal, jawaban: hasilJawaban});

            if(!postJawab || postJawab.status === 'fail'){
                toast.update(idToast, { type: "error", render: "Ups!, Gagal Menjawab Soal, Silahkan di coba lagi!",
                    isLoading: false,
                    position: "bottom-center",
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }

            if(postJawab.status === 'success'){
                handleSetJawaban(hasilJawaban);
                handleSetUpdateStatus(true);

                toast.update(idToast, { type: "success", render: "Jawaban Terkirim",
                    isLoading: false,
                    position: "bottom-center",
                    autoClose: 300,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        } catch (error) {
            toast.update(idToast, { type: "error", render: "Ups!, Gagal Menjawab Soal, Silahkan di coba lagi!",
                isLoading: false,
                autoClose: 3000,
                position: "bottom-center",
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
      }
    
    useEffect(() => {
        // Clean Jawaban
        handleSetJawaban('');

        if(dataSoal?.jawab){
            // Deteksi tipe Soal Pilihan
            if(dataSoal?.jawab.length === 1 ){
                handleSetJawaban(dataSoal?.jawab);
            }
        }
    },[dataSoal]);
      

  return (
    <div className="px-4 py-5 bg-gray-50 sm:px-6">
        <div className="text-sm font-medium text-gray-500">Pilih Jawaban</div>
        <div className="p-5">
        
            <div>
                <div className="flex items-center mb-4">
                    <input name="rdoJawab" id="rdoJawab" type="radio" value="A" checked={jawaban === "A"}
                    onClick={(e) => {
                    submitJawaban(idMapel, dataSoal?.soal?.id, e.target.value);
                    }}
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 cursor-pointer focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                    <div className='flex'>
                        {/* <label htmlFor="rdoJawab" className="ml-2 text-sm font-medium text-gray-900">A. </label> */}
                        <label className="ml-2 text-sm font-medium text-gray-900 al" dangerouslySetInnerHTML={{ __html: dataSoal?.soal?.pilA }}></label>
                    </div>
                </div>
                <div className="pl-5">
                { dataSoal?.soal?.pilA_file && (
                    <img className="max-w-screen-md pb-5" src={`${process.env.REACT_APP_FILE_HOST}/soal/${dataSoal?.soal?.pilA_file}`} alt="gambar1 soal" />
                )}
                </div>
            </div>

            <div>
                <div className="flex items-center mb-4">
                    <input name="rdoJawab" id="rdoJawab" type="radio" value="B" checked={jawaban === "B"}
                    onClick={(e) => {
                    submitJawaban(idMapel, dataSoal?.soal?.id, e.target.value);
                    }}
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 cursor-pointer focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                    <div className='flex'>
                        {/* <label htmlFor="rdoJawab" className="ml-2 text-sm font-medium text-gray-900">B. </label> */}
                        <label className="ml-2 text-sm font-medium text-gray-900 al" dangerouslySetInnerHTML={{ __html: dataSoal?.soal?.pilB }}></label>
                    </div>
                </div>
                <div className="pl-5">
                { dataSoal?.soal?.pilB_file && (
                    <img className="max-w-screen-md pb-5" src={`${process.env.REACT_APP_FILE_HOST}/soal/${dataSoal?.soal?.pilB_file}`} alt="gambar1 soal" />
                )}
                </div>
            </div>

            <div>
                <div className="flex items-center mb-4">
                    <input name="rdoJawab" id="rdoJawab" type="radio" value="C" checked={jawaban === "C"}
                    onClick={(e) => {
                    submitJawaban(idMapel, dataSoal?.soal?.id, e.target.value);
                    }}
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 cursor-pointer focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                    <div className='flex'>
                        {/* <label htmlFor="rdoJawab" className="ml-2 text-sm font-medium text-gray-900">C. </label> */}
                        <label className="ml-2 text-sm font-medium text-gray-900 al" dangerouslySetInnerHTML={{ __html: dataSoal?.soal?.pilC }}></label>
                    </div>
                </div>
                <div className="pl-5">
                { dataSoal?.soal?.pilC_file && (
                    <img className="max-w-screen-md pb-5" src={`${process.env.REACT_APP_FILE_HOST}/soal/${dataSoal?.soal?.pilC_file}`} alt="gambar1 soal" />
                )}
                </div>
            </div>

            <div>
                <div className="flex items-center mb-4">
                    <input name="rdoJawab" id="rdoJawab" type="radio" value="D" checked={jawaban === "D"}
                    onClick={(e) => {
                    submitJawaban(idMapel, dataSoal?.soal?.id, e.target.value);
                    }}
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 cursor-pointer focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                    <div className='flex'>
                        {/* <label htmlFor="rdoJawab" className="ml-2 text-sm font-medium text-gray-900">D. </label> */}
                        <label className="ml-2 text-sm font-medium text-gray-900 al" dangerouslySetInnerHTML={{ __html: dataSoal?.soal?.pilD }}></label>
                    </div>
                </div>
                <div className="pl-5">
                { dataSoal?.soal?.pilD_file && (
                    <img className="max-w-screen-md pb-5" src={`${process.env.REACT_APP_FILE_HOST}/soal/${dataSoal?.soal?.pilD_file}`} alt="gambar1 soal" />
                )}
                </div>
            </div>

            <div>
                <div className="flex items-center mb-4">
                    <input name="rdoJawab" id="rdoJawab" type="radio" value="E"
                    checked={jawaban === "E"}
                    onClick={(e) => {
                    submitJawaban(idMapel, dataSoal?.soal?.id, e.target.value);
                    }}
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 cursor-pointer focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                    <div className='flex'>
                        {/* <label htmlFor="rdoJawab" className="ml-2 text-sm font-medium text-gray-900">E. </label> */}
                        <label className="ml-2 text-sm font-medium text-gray-900 al" dangerouslySetInnerHTML={{ __html: dataSoal?.soal?.pilE }}></label>
                    </div>
                </div>
                <div className="pl-5">
                { dataSoal?.soal?.pilE_file && (
                    <img className="max-w-screen-md pb-5" src={`${process.env.REACT_APP_FILE_HOST}/soal/${dataSoal?.soal?.pilE_file}`} alt="gambar1 soal" />
                )}
                </div>
            </div>
        </div>
        <ToastContainer />
    </div>
  )
}

export default Pilihan

// Expected data : A