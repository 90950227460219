import React, { useEffect, useState } from "react";

import ujian from "constants/api/ujian";
import { setAuthorizationHeader } from "configs/axios";
import { useHistory } from "react-router-dom";
import LoadingSoalNomor from "../parts/ujian/loadings/LoadingSoalNomor";
import Headbar from "parts/Headbar";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export default function Rules() {
  const history = useHistory();
  const [loading, setLoading] = useState(true);

  const fetchData = async () =>{
    setLoading(true);
    try {
      
      if (localStorage.getItem("SIM:token")) {
        setAuthorizationHeader(JSON.parse(localStorage.getItem("SIM:token")).token);
      }

      const rules = await ujian.getRules();

      if(rules){
        // Jika Status Rules Selesai
        if(rules === 'Selesai'){
          // console.log('UJIAN BERAKHIR !!!!');
          history.push(`/`);
        }
  
        // Jika Status rules = 0 / 1 maka next soal
        if(rules.status_ujian === 0 || rules.status_ujian === 1){
          history.push(`/ujian/${rules.mapel_id_berlangsung}`);
        }
      } else {
        history.push(`/`);
      }
    } catch (error) {
      console.error(error.message);
      toast.error('Ups! ada kesalahan,'+error.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
    }
    setLoading(false);
  }

  useEffect(() => {
    fetchData();    
  },[history]);

  return (
    <>
    <div className="flex bg-gray-250">
        <main className="flex-1 h-screen">
          <div className="w-full h-16 bg-blue-500">
            <Headbar />
          </div>
          <div className="px-4 mt-10 sm:px-16">
              <LoadingSoalNomor />
          </div>
        </main>
        <ToastContainer />
    </div>
    </>
  );
}
