import React, { useState } from "react";
import { withRouter } from "react-router-dom";

import users from "constants/api/users";

import useForm from "helpers/hooks/useForm";
import fieldErrors from "helpers/fieldErrors";

import Select from "components/Form/Select";
import Input from "components/Form/Input";
import { useQuery } from "react-query";
import kelas from "constants/api/kelas";


async function fetchKelas() {
  return await kelas.activeKelas();
}


function RegisterForm({ history }) {

  const { data: kelasData, isLoading: kelasIsLoading, isError: kelasIsError, error: kelasError } = useQuery('kelasTps', fetchKelas);


  const [
    { nama,
      username,
      password,
      repassword,
      nis,
      asal_sekolah,
      lahir,
      tgl_lahir,
      hp,
      id_kelas,
      id_sesi,
    },
    setState,
  ] = useForm({
    name: "",
    username: "",
    password: "",
    repassword: "",
    nis: "",
    asal_sekolah: "",
    lahir: "",
    tgl_lahir: "",
    hp: "",
    id_kelas: "",
    id_sesi: "",
  });

  const [errors, seterrors] = useState(null);

  function submit(e) {
    e.preventDefault();

    users
      .register({
        nama,
        username,
        password,
        repassword,
        nis,
        asal_sekolah,
        lahir,
        tgl_lahir,
        hp,
        id_kelas,
        id_sesi,
      })
      .then((res) => {
        history.push("/register-success");
      })
      .catch((err) => {
        seterrors(err?.response?.data?.message);
      });
  }

  const ERRORS = fieldErrors(errors);

  return (
    <div className="pb-24">
      <div className="w-full">
        <h1 className="text-4xl text-gray-900 mb-6">
          <span className="font-bold">Pendaftaran</span> Simulasi
        </h1>
        <form onSubmit={submit}>
          <div className="md:flex">
            <Input
              value={nama}
              error={ERRORS?.name?.message}
              name="nama"
              onChange={setState}
              placeholder="Your Name"
              labelName="Nama Lengkap"
            />

            <Input
              value={username}
              error={ERRORS?.username?.message}
              name="username"
              type="text"
              onChange={setState}
              placeholder="Your username"
              labelName="Username"
            />
          </div>

          <div className="md:flex">
            <Input
              value={password}
              error={ERRORS?.password?.message}
              name="password"
              type="password"
              onChange={setState}
              placeholder="Your Password"
              labelName="Password"
            />
            <Input
              value={repassword}
              error={ERRORS?.password?.message}
              name="repassword"
              type="password"
              onChange={setState}
              placeholder="Ulangi Password"
              labelName="Retype Password"
            />
          </div>

          <div className="md:flex">
            <Input
              value={nis}
              error={ERRORS?.nis?.message}
              name="nis"
              type="text"
              onChange={setState}
              placeholder="NISN"
              labelName="NISN"
            />

            <Input
              value={asal_sekolah}
              error={ERRORS?.asal_sekolah?.message}
              name="asal_sekolah"
              type="text"
              onChange={setState}
              placeholder="Nama Sekolah / Instansi"
              labelName="Asal Sekolah / Instansi"
            />
          </div>
          
          <div className="md:flex">
            <Input
              value={lahir}
              error={ERRORS?.lahir?.message}
              name="lahir"
              type="text"
              onChange={setState}
              placeholder="Kota Kelahiran"
              labelName="Tempat Lahir"
            />

            <Input
              value={tgl_lahir}
              error={ERRORS?.tgl_lahir?.message}
              name="tgl_lahir"
              type="date"
              onChange={setState}
              placeholder="tgl/bulan/tahun"
              labelName="Tanggal Lahir"
            />
          </div>

          <div className="md:flex">
            <Input
              value={hp}
              error={ERRORS?.hp?.message}
              name="hp"
              type="text"
              onChange={setState}
              placeholder="085123456789"
              labelName="No HP/ WA"
            />
          </div>

          <div className="md:flex justify-between">
            <Select
              labelName="Kelas Ujian"
              name="id_kelas"
              value={id_kelas}
              fallbackText="Pilih Kelas Ujian"
              onClick={setState}
            >
              { kelasData?.map((item, index) => {
                return (
                  <option key={index} value={item?.id}>{item?.nama}</option>
                )
              })}
              {/* <option value="3">SOSHUM</option>
              <option value="4">KEDINASAN</option> */}
            </Select>

            {/* <Select
              labelName="Sesi Ujian"
              name="id_sesi"
              value={id_sesi}
              fallbackText="Pilih Sesi Ujian"
              onClick={setState}
            >
              <option value="1">Kamis, 1 Desember 2022 - 08:00 - 10:00</option>
              <option value="2">Kamis, 2 Desember 2022 - 08:00 - 10:00</option>
              <option value="3">Kamis, 3 Desember 2022 - 08:00 - 10:00</option>
            </Select> */}

          </div>

          {/* {profession === "others" && (
            <Input
              value={otherProfession}
              error={ERRORS?.otherProfession?.message}
              name="otherProfession"
              type="text"
              onChange={setState}
              placeholder="Your occupation"
              labelName="Other Occupation"
            />
          )} */}

          <button
            type="submit"
            className="bg-orange-500 hover:bg-orange-400 transition-all duration-200 focus:outline-none shadow-inner text-white px-6 py-3 mt-4 w-full"
          >
            Daftar
          </button>
        </form>
      </div>
    </div>
  );
}

export default withRouter(RegisterForm);
