import React from "react";
import LoadingSoal from './LoadingSoal';
import LoadingNomor from './LoadingNomor';

export default function LoadingSoalNomor() {
  return (
    <div className="flex flex-col lg:flex-row">
        <div className="xl:basis-4/5 lg:basis-3/5 mt-10 overflow-hidden bg-white shadow w-500 sm:rounded-lg">
        <LoadingSoal />
        </div>
        <div className="xl:basis-1/5 lg:basis-2/5 mt-10 lg:ml-5 max-w-400">
        <LoadingNomor />
        </div>
    </div>
  );
}
