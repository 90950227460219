import React from "react";

export default function EmptyState(props) {
  return (
    <section className="relative z-50 flex items-center h-screen bg-white">
      <div className="w-full py-12 mx-auto text-center sm:w-5/12">
        
        <h1 className="mt-12 text-xl text-gray-900">Mohon Maaf, Ada kesalahan dalam memuat Soal, Silahkan Coba beberapa saat lagi</h1>
        {/* <p className="mx-auto mt-4 mb-8 text-lg text-center text-gray-600">
          Hubungi Ad
          min
        </p> */}
        <div className="m-10">
          <button  className="bg-orange-500 hover:bg-orange-400 transition-all duration-200 focus:outline-none shadow-inner rounded-md text-white px-6 py-3"
          type="submit"
          onClick="window.location.reload()">Coba Lagi</button>
        </div>
        <p className="mx-auto mt-4 mb-8 text-lg text-center text-gray-600">{props.children ?? ''}</p>
      </div>
    </section>
  );
}
