import React from "react";
import propTypes from "prop-types";

import { Link, withRouter } from "react-router-dom";

// import { ReactComponent as Logo } from "assets/images/logo.svg";

function Header({ onLight, location }) {
  // const linkColor = onLight ? "text-white sm:text-gray-900 " : "text-white";
  const [toggleMenu, setToggleMenu] = React.useState(false);

  // const linkCTA =
  //   location.pathname.indexOf("/login") > -1 ? `/register` : `/login`;
  // const textCTA = location.pathname.indexOf("/login") > -1 ? "Daftar" : "Masuk";

  // const classNameLogo = onLight
  //   ? toggleMenu
  //     ? "on-dark"
  //     : "on-light"
  //   : "on-light";

  return (
    <header
      className={[
        "flex justify-between items-center",
        toggleMenu ? "fixed w-full -mx-4 px-4" : "",
      ].join(" ")}
    >
      <div style={{ height: 54 }} className="z-50">
        {/* <Logo className={classNameLogo}></Logo> */}
        <img src="assets/images/logoaplikasi-black.webp" alt="logo" />
      </div>
      <div className="flex sm:hidden">
        <button
          onClick={() => setToggleMenu((prev) => !prev)}
          className={["toggle z-50", toggleMenu ? "active" : ""].join(" ")}
        ></button>
      </div>
      
    </header>
  );
}

Header.propTypes = {
  onLight: propTypes.bool,
};

export default withRouter(Header);
