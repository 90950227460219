import React from 'react'

const EmptyTest = (props) => {
    const title = props.title; 
  return (
    <div className="grid gap-4 lg:grid-cols-3 md:grid-cols-1">
        <div className="col-span-2 mt-10 overflow-hidden bg-white shadow sm:rounded-lg ">
        <div className="px-4 py-5 rounded-t-lg sm:px-6 bg-gradient-to-br from-red-400 to-red-500">
            <h3 className="text-lg font-medium leading-6 text-white">Konfirmasi Tes {title}</h3>
            <p className="max-w-2xl mt-1 text-sm text-white"><i className='fa fa-warning'></i> Informasi Tes {title}</p>
        </div>
        <div className="">
            <dl>
            <div className="px-4 py-5 bg-gray-50 sm:px-6">
                Tidak ada tes {title} untuk hari ini!
            </div> 
            </dl>
        </div>
        </div>
    </div>
  )
}

export default EmptyTest