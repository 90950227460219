import axios from "configs/axios";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    getTime: () => axios.get(`/ujian/time`).then((res) => res.data),
    getRules: () => axios.get(`/ujian/rules`).then((res) => res.data),
    getSoal: (idmapel, nosoal) => axios.get(`/ujian/soal/${idmapel}/${nosoal}`).then((res) => res.data),
    getStatus: (idmapel) => axios.get(`/ujian/status/${idmapel}`).then((res) => res.data),
    updateSelesai: (idmapel) => axios.put(`/ujian/selesai/${idmapel}`).then((res) => res.data),
    getNomorSoal: (idmapel) => axios.get(`/ujian/nomorsoal/${idmapel}`).then((res) => res.data),
    jawabSoal: (payload) => axios.post(`/ujian/jawab`, payload).then((res) => res),
    jawabRagu: (payload) => axios.post(`/ujian/ragu`, payload).then((res) => res)
};
