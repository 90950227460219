import React, { useEffect } from "react";

import _ from 'lodash';
import { ToastContainer, toast } from 'react-toastify';

// import { useDispatch, useSelector } from "react-redux";
import ujian from "constants/api/ujian";

import { useQuery } from 'react-query';
import { setAuthorizationHeader } from "configs/axios";
import Headbar from "parts/Headbar";
import { useParams } from "react-router-dom";
import Soal from "parts/ujian/Soal";
import Jawaban from "parts/ujian/Jawaban";
import EmptyTest from "parts/EmptyTest";
import EmptySoal from "parts/EmptySoal";
import LoadingSoalNomor from "../parts/ujian/loadings/LoadingSoalNomor";


import NomorProvider from '../context/nomor'
import UpdateStatusProvider from "context/updatestatus";
import JawabanProvider from "context/jawaban";

async function fetchStatus(idMapel) {
  if (localStorage.getItem("SIM:token")) {
    setAuthorizationHeader(JSON.parse(localStorage.getItem("SIM:token")).token);
  }
  const dataStatus = await ujian.getStatus(idMapel);
  return dataStatus;
}

export default function Ujian() {
  const { idmapel } = useParams();

  const { data: dataStatus, isLoading: isLoadingStatus, isError: isErrorStatus } = useQuery(['status', idmapel], async () => fetchStatus(idmapel));

  useEffect(() => {
    if (localStorage.getItem("SIM:token")) {
      setAuthorizationHeader(JSON.parse(localStorage.getItem("SIM:token")).token);
    }
  },[dataStatus, idmapel]);

  return (
    <NomorProvider>
      <JawabanProvider>
        <UpdateStatusProvider>
          <div className="flex bg-gray-250">
            {/* <Sidebar></Sidebar> */}
            <main className="flex-1 h-screen">
              <div className="w-full h-16 bg-blue-500">
                <Headbar />
              </div>
              <div className="px-4 mt-10 sm:px-16">
              { isLoadingStatus ? (
                  <LoadingSoalNomor />
                ) : isErrorStatus ? (
                  <>
                    <EmptySoal></EmptySoal>
                  </>
                ) : (dataStatus && !_.isEmpty(dataStatus)) ? (
                  <div className="flex flex-col lg:flex-row">
                      <div className="xl:basis-4/5 lg:basis-3/5 mt-10 overflow-hidden bg-white shadow w-500 sm:rounded-lg">
                        <Soal maxNumSoal={dataStatus.jml_soal} idMapel={dataStatus.id_mapel} title={dataStatus.judul_mapel} lama_ujian={dataStatus.lama_ujian}/>
                      </div>
                      <div className="xl:basis-1/5 lg:basis-2/5 mt-10 lg:ml-5 max-w-400">
                        <Jawaban />
                      </div>
                  </div>
                  ) : (
                    <EmptySoal />
                  )
                }
              </div>
            </main>
          </div>
          <ToastContainer />
        </UpdateStatusProvider>
      </JawabanProvider>
    </NomorProvider>
  );
}
