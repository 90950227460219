import React from "react";
import PropTypes from 'prop-types'


KofirmasiUjian.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
}


export default function KofirmasiUjian(props) {
  const data = props.data;

  let text = '';
  let color = '';

  switch (data.status_ujian) {
    case 0:
      text = 'Baru';
      color = 'bg-blue-400';
      break;
    case 1:
      text = 'Berlangsung';
      color = 'bg-green-400';
      break;
    case 3:
      text = 'Selesai';
      color = 'bg-gray-400';
      break;
    default:
      text = 'Selesai';
      color = 'bg-gray-400';
      break;
  }


  return (
    <>
      <div className="px-4 py-5 rounded-t-lg sm:px-6 bg-gradient-to-br from-blue-400 to-blue-500">
        <h3 className="text-lg font-medium leading-6 text-white">Konfirmasi Tes {props.title ?? ''}</h3>
        <p className="max-w-2xl mt-1 text-sm text-white">Informasi Ujian {props.title ?? ''}</p>
      </div>
      <div className="border-b-2 border-gray-200">
        <dl>
          <div className="px-4 py-5 bg-gray-50 sm:px-6">
            <div className="text-sm font-medium text-gray-500">Nama Tes</div>
            <div className="mt-1 text-sm text-gray-900">{data?.nama}</div>
          </div>
          <div className="px-4 py-3 bg-white sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Status Tes</dt>
            <dd className="py-3 mt-1 text-sm text-white bg--400"><span className={`py-2 px-3 rounded-lg ${color}`}>{text}</span></dd>
          </div>
          <div className="px-4 py-3 bg-gray-50 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Tanggal Ujian</dt>
            <dd className="mt-1 text-sm text-gray-900">{data?.tgl_ujian}</dd>
          </div>
          <div className="px-4 py-3 bg-white sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Daftar Ujian {props.title ?? ''}</dt>
            <dd className="mt-1 text-sm text-gray-900">
            <ul className="border-gray-200 divide-y divide-gray-200 rounded-md">
              {data?.Mapels?.map?.((item, index) => {
                return (
                  <li key={index} className="flex items-center justify-between py-2 pl-3 pr-4 text-sm">
                    <div className="flex items-center justify-start flex-1 w-0">
                      <span className="flex-1 w-0 ml-2 truncate">{item?.nama} <span className="text-red-400">({item?.lama_ujian} menit)</span></span>
                    </div>
                    {/* <div className="flex-shrink-0 ml-4">
                      <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500">
                        {text}
                      </a>
                    </div> */}
                  </li>
                )
                })}
              </ul>
            </dd>
          </div>
          <div className="px-4 py-3 bg-gray-50 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Jumlah Soal</dt>
            <dd className="mt-1 text-sm text-gray-900">{data?.jml_soal ?? 0} Soal</dd>
          </div>
          <div className="px-4 py-3 mb-5 bg-gray-50 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Alokasi Waktu Tes</dt>
            <dd className="mt-1 text-sm text-gray-900">{data?.lama_ujian ?? 0} Menit</dd>
          </div>
        </dl>
      </div>
    </>
  )
  ;
}
