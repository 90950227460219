import React from "react";
import { Link } from "react-router-dom";
// import { Offline, Online } from "react-detect-offline";

function Headbar({ onLight, location }) {

  return (
    
    <div>
    <nav className="flex flex-wrap items-center justify-between py-2 px-5 bg-blue-500">
        <div className="flex ml-10 lg:ml-5">
            {/* <div className="block lg:hidden">
                <button className="flex items-center px-3 py-2 mr-3 text-teal-200 border border-teal-400 rounded hover:text-white hover:border-white">
                <svg className="w-4 h-4 fill-current" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Menus</title><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/></svg>
                </button>
            </div> */}
            <div className="flex items-center flex-shrink-0 mr-6 text-white">
                <Link to="/">
                    <img className="mb-0" src="/assets/images/logoaplikasi.png" style={{ height: "45px"}} alt="logo simulasi"/>
                    {/* <span className="text-xl font-semibold tracking-tight">SIMULASI AI</span> */}
                </Link>
            </div>
        </div>
        <div className="flex-grow block w-10 lg:flex lg:items-center lg:w-auto">
            <div className="relative rounded-full bg-gray-500 h-8 w-8">
                <img src={`${process.env.PUBLIC_URL}/assets/images/user.png`} className="relative rounded-full w-8 h-8" alt="profile pic" />

                {/* <Online>
                    <div className="absolute top-0 right-0 rounded-full bg-green-400 h-3 w-3 border-2 border-white">
                        <span className="animate-ping inline-flex h-full w-full rounded-full bg-green-400 opacity-75"></span>
                    </div>
                </Online>
                <Offline>
                    <div className="absolute top-0 right-0 rounded-full bg-red-400 h-3 w-3 border-2 border-white">
                        <span className="animate-ping inline-flex h-full w-full rounded-full bg-red-400 opacity-75"></span>
                    </div>
                </Offline> */}

            </div>
        </div>
    </nav>
    {/* <div className="w-full">        
        <Offline>
            <div className="bg-red-400 h-4 text-xs">
                Jaringan anda Offline
            </div>
        </Offline>
    </div> */}
  </div>
  );
}

export default Headbar;
