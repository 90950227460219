import {
  FETCH_KELASTPS,
  FETCH_KELASTPA,
  STATUS_COURSES,
  MESSAGE_KELAS,
} from "constants/types/kelas";

const initialState = {
  data: {},
  total: 0,
  status: "idle",
  message: "",
};

// eslint import/no-anonymous-default-export
export default function kelas(state = initialState, action) {
  switch (action.type) {
    case STATUS_COURSES:
      return {
        ...state,
        status: action.payload,
      };

    case FETCH_KELASTPS:
      return {
        ...state,
        data:
          action.payload,
        total: action.payload?.length ?? 0,
        status: "ok",
      };

    case FETCH_KELASTPA:
      return {
        ...state,
        data:
          action.payload?.reduce?.((acc, item) => {
            acc[item.id] = item;
            return acc;
          }, {}) ?? {},
        total: action.payload?.length ?? 0,
        status: "ok",
      };

    case MESSAGE_KELAS:
      return {
        ...state,
        message: action.payload,
        status: "error",
      };

    default:
      return state;
  }
}
