import React from "react";

export default function LoadingSoal() {
  return (
    <section className="flex flex-col relative z-50 pb-5 bg-white">
      <div className="flex flex-row justify-between mb-3 px-4 py-3 rounded-t-lg sm:px-6 bg-gradient-to-br from-gray-400 to-gray-500">
          <div className="flex animate-pulse flex-row space-x-5">
            <div className="flex flex-col space-y-3 w-full">
                <div className=" w-1/2 bg-gray-300 h-3 rounded-md "></div>
                <div className="w-32 bg-gray-300 h-3 rounded-md "></div>
            </div>
          </div>
        </div>
        <div className="flex animate-pulse flex-row p-5 space-x-5">
          <div className="flex flex-col space-y-3  w-full">
              <div className=" w-1/2 bg-gray-300 h-6 rounded-md "></div>
              <div className="w-32 bg-gray-300 h-6 rounded-md "></div>
          </div>
        </div>
        <div className="flex animate-pulse flex-row p-5 space-x-5">
          <div className="flex flex-col space-y-3 w-full">
              <div className="w-full bg-gray-300 h-6 rounded-md "></div>
              <div className="w-full bg-gray-300 h-6 rounded-md "></div>
              <div className="w-1/2 bg-gray-300 h-6 rounded-md "></div>
          </div>
        </div>
        <div className="flex animate-pulse flex-row p-5 space-x-5">
          <div className="flex flex-col space-y-3 w-full">
              <div className="w-1/4 bg-gray-300 h-6 rounded-md "></div>
              <div className="w-1/3 bg-gray-300 h-6 rounded-md "></div>
              <div className="w-1/4 bg-gray-300 h-6 rounded-md "></div>
              <div className="w-1/3 bg-gray-300 h-6 rounded-md "></div>
          </div>
        </div>
    </section>
  );
}
