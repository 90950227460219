import React from "react";

import { Link } from "react-router-dom";

export default function Unauthenticated({
  fallbackUrl,
  fallbackText,
  external,
}) {
  return (
    <section className="flex flex-col items-center h-screen">
      <img
        src={`${process.env.PUBLIC_URL}/assets/images/illustration-private.jpg`}
        alt="you are not supposed here, please login"
      />
      <h1 className="mt-12 text-3xl text-gray-900">Wow! How are you here?</h1>
      <p className="mx-auto mt-4 text-lg text-center text-gray-600 mtb-8 lg:w-3/12 xl:w-2/12">
        Seems like you do not have access for this page. We are sorry.
      </p>
      {external ? (
        <a
          className="px-6 py-3 mt-5 text-white transition-all duration-200 bg-orange-500 shadow-inner hover:bg-orange-400 focus:outline-none"
          href={fallbackUrl}
        >
          {fallbackText || "Logging me in"}
        </a>
      ) : (
        <Link
          className="px-6 py-3 mt-5 text-white transition-all duration-200 bg-orange-500 shadow-inner hover:bg-orange-400 focus:outline-none"
          to={fallbackUrl || "/login"}
        >
          {fallbackText || "Logging me in"}
        </Link>
      )}
    </section>
  );
}
