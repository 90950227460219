import axios from "configs/axios";

const hasilUjian = () => axios.get(`/hasil/nilai`).then((res) => res.data);
const score = () => axios.get(`/hasil/score`).then((res) => res.data);

const hasil = {
  hasilUjian,
  score,
};

export default hasil;
