import React, { useEffect } from "react";
import _ from 'lodash';
import { setAuthorizationHeader } from "configs/axios";
import { useQuery } from "react-query";
import ujian from "constants/api/ujian";
import { useParams } from "react-router-dom";
import { useHistory } from 'react-router-dom'
import { useNomor } from "context/nomor";
import { useUpdateStatus } from "context/updatestatus";
import LoadingNomor from "./loadings/LoadingNomor";


async function fetchNomorSoal(idMapel) {
  if (localStorage.getItem("SIM:token")) {
    setAuthorizationHeader(JSON.parse(localStorage.getItem("SIM:token")).token);
  }
  const dataStatus = await ujian.getNomorSoal(idMapel);
  return dataStatus;
}

export default function Jawaban() {
  const history = useHistory()
  const { idmapel } = useParams();

  const nomorContext = useNomor();
  const { nomor, handleSetNomor } = nomorContext;
  const updateStatusContext = useUpdateStatus();
  const { updateStatus } = updateStatusContext;
  // TODO: Refetch jawaban if updateStatus Changed

  const handleSelesai = async () => {

    const confirmed = window.confirm("Anda Yakin Selesaikan Ujian ?");
    
    if (confirmed) {
      if (localStorage.getItem("SIM:token")) {
        setAuthorizationHeader(await JSON.parse(localStorage.getItem("SIM:token")).token);
      }

      await ujian.updateSelesai(idmapel);
      history.push(`/rules`);
    }    
  }

  const { data: dataNomoSoal, isLoading: isLoadingNomorSoal, isError: isErrorNomorSoal, error: errorNomorSoal } = useQuery(['nomor_soal', idmapel, nomor], async () => fetchNomorSoal(idmapel));

  useEffect(() => {
    // console.log('Render Componen JAWABAN');
  },[dataNomoSoal, updateStatus, nomor]);


  return (
    <div className="p-5 bg-white rounded-lg">
      <div className="flex flex-row justify-between mb-3">
        <div className="px-3 py-2"><b>Nomor Soal </b></div>
        {/* TOMBOL SELESAI */}
        <div onClick={handleSelesai} className="px-3 py-2 text-white bg-red-600 rounded-lg cursor-pointer active:bg-red-800 hover:bg-red-400">Selesai</div>
      </div>
      { isLoadingNomorSoal ? (
          <LoadingNomor />
        ) : isErrorNomorSoal ? (
          <>
            <b>There's an error: {errorNomorSoal.message}</b>
          </>
        ) : (dataNomoSoal && !_.isEmpty(dataNomoSoal)) ? (
            dataNomoSoal?.map((item) => {
              return (
                <div className="mt-3" key={item.materi}>
                  <div className="w-full px-2 py-1 text-sm text-center bg-blue-200 rounded-t-lg">{item.materi ?? 'Other'}</div>
                  <div className="grid grid-cols-5 lg:grid-cols-5 xl:grid-cols-6 gap-4 overflow-y-auto border-2 border-blue-200 p-5 rounded-b-lg">
                    {item.nomors.map((element) => {
                      let color = 'gray';
                      let selectStatus = '';
      
                      switch (element.status) {
                        case 0:
                          color = 'bg-gray-200';
                          break;
                      
                        case 1:
                          color = 'bg-green-200';
                          break;
                      
                        case 2:
                          color = 'bg-yellow-200';
                          break;
                      
                        default:
                          break;
                      }
      
                      if(Number(nomor) === Number(element.nomor)){
                        selectStatus = 'ring ring-offset-2 ring-4'
                      }
      
      
                      return (
                        <span
                          key={element.nomor}
                          onClick={() => handleSetNomor(element.nomor)}
                          className={`text-sm w-8 py-1 text-center ${color} rounded-lg cursor-pointer
                          ${selectStatus}
                          hover:ring hover:ring-offser-2 hover:ring-4 hover:ring-yellow-200hover:bg-yellow-400`}
                        >{element.nomor}</span>
                      )
                    })}
                  </div>
                </div>
              )
            })
          ) : (
            <>
             Nomor Soal Kosong
            </>
          )
      }
    </div>
  )
  ;
}