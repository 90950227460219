import React, { useEffect } from "react";

import Header from "parts/Header";
import Footer from "parts/Footer";
import { Link } from "react-router-dom";

export default function Register({ history }) {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <>
      <section className="container mx-auto pt-10 px-4 guest-page relative z-10">
        <Header onLight></Header>
      </section>
      <section className="container mx-auto pt-10 px-4">
      <div className="pb-24">
        <div className="w-full">
          <h1 className="text-4xl text-gray-900 mb-6">
            <span className="font-bold">Pendaftaran</span> Berhasil !
          </h1>
            <span className="font-bold">Silahkan Konfirmasi Konfirmasi Pendaftaran anda ke admin</span>
        </div>
        <div className="mt-10">
          <Link to={'/login'} className="bg-orange-500 hover:bg-orange-400 transition-all duration-200 focus:outline-none shadow-inner text-white px-6 py-3 mt-4 w-full">Ke Halaman Lgin</Link>
        </div>
      </div>
      </section>
      <section className="mt-24 bg-indigo-1000 py-12">
        <Footer></Footer>
      </section>
    </>
  );
}
