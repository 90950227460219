import axios from "configs/axios";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  login: (credentials) => axios.post("/auth/login", credentials),
  register: (payload) => axios.post("/auth/register", payload),
  refresh: (credentials) =>
    axios.post("/refresh_tokens", {
      refresh_token: credentials.refresh_token,
      username: credentials.username,
    }),
  details: () => axios.get("/auth/profile"),
  update: (data) => axios.put("/auth", data),
  logout: () => axios.post("/auth/logout"),
};

